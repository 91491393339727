<template>
  <div class="search-form-result">
    <div class="search-form-result__image">
      <NuxtLink :to="localePath(route)" :data-hoverable="$t('cursor.view')">
        <WidgetPicture
          :image="{
            custom: props.result.cover?.[0],
          }"
          :lazyLoad="true"
          :alt="props.result.cover?.[0]?.title"
        />
      </NuxtLink>
    </div>

    <div class="search-form-result__content">
      <NuxtLink :to="localePath(route)" class="link-underline-reverse-1">
        <span class="text">{{ props.result.title }}</span>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchFormResult',
}
</script>

<script setup>
const props = defineProps({
  result: Array,
})

const route = computed(() => {
  switch (props.result.__typename) {
    case 'products_product_Entry':
      return {
        name: 'product-slug',
        params: { slug: props.result.slug },
      }
      break
    case 'products_family_Entry':
      return {
        name: 'product-family-slug',
        params: { slug: props.result.slug },
      }
      break
    case 'projects_projects_Entry':
      return {
        name: 'projects-slug',
        params: { slug: props.result.slug },
      }
      break
    case 'news_news_Entry':
      return {
        name: 'news-slug',
        params: { slug: props.result.slug },
      }
      break
    /* case 'designersEntries':
      return {
        name: 'designers-slug',
        params: { slug: props.result.slug },
      }
      break
    case 'finishingsEntries':
      return {
        name: 'finishes-slug',
        params: { slug: props.result.slug },
      }
      break */
  }
})
</script>

<style lang="scss">
@import './style.scss';
</style>
