<template>
  <div
    class="navigation-bar"
    ref="navigationBar"
    :class="{
      '--fixed': props.fixed,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WidgetNavigationBar',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const props = defineProps({
  fixed: Boolean,
})

const navigationBar = ref(null)
const duration = 0.4

onMounted(() => {
  const showAnim = gsap
    .from(navigationBar.value, {
      yPercent: -100,
      paused: true,
      duration,
    })
    .progress(1)

  ScrollTrigger.create({
    start: 'top top',
    end: 99999,
    onUpdate: (self) => {
      self.direction === -1 ? showAnim.play() : showAnim.reverse()
    },
  })
})
</script>

<style lang="scss">
@import './style.scss';
</style>
