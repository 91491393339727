<template>
  <aside class="footer-buttons">
    <div class="footer-buttons__wrap">
      <div class="footer-buttons__wrap__button p-top-3 p-bot-3">
        <WidgetLinkMainUnderline
          :link="{ key: 'catalogues-footer', route: { name: 'catalogues' } }"
          :diagonal="true"
        />
      </div>
      <div class="footer-buttons__wrap__button">
        <!-- <WidgetLinkMainUnderline
          :link="{ key: 'newsletter-footer', href: 'https://www.zavaluce.it' }"
          :diagonal="true"
        /> -->
        <WidgetLinkMainUnderline
          :link="{
            key: 'distributor-footer',
            route: {
              name: 'contacts-distributors-region',
              params: {
                region:
                  nuxtApp.$i18n.locale.value === 'it' ? 'italia' : 'italy',
              },
            },
          }"
          :diagonal="true"
        />
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'FooterButtons',
}
</script>

<script setup>
const nuxtApp = useNuxtApp()
</script>

<style lang="scss">
@import './style.scss';
</style>
