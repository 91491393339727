<template>
  <!-- Nav Tacon -->
  <!-- Quando sono a risoluzioni stupide tipo 1280x720 sotto la navigazione si vede il nero del footer sticky (che fa l'effetto di svelatura allo scroll). Nav tacon serve a nasconderlo. -->
  <div class="nav-tacon"></div>
  <!-- Navigation Sticky -->
  <WidgetNavigationBar :fixed="openOverlay" v-if="data">
    <nav class="nav-menu" ref="menuBar" :class="{ '--open': openOverlay }">
      <!-- Hamburger -->
      <WidgetHamburger
        class="nav-menu__hamburger"
        @click="toggleOverlay"
        ref="hamburger"
      />
      <!-- Logo -->
      <NuxtLink :to="localePath({ name: 'index' })" class="nav-menu__logo">
        <img
          src="~/assets/img/ico/logo-zava-black.svg"
          width="145"
          height="30"
          :alt="runtimeConfig.public.siteName"
        />
      </NuxtLink>

      <!-- Lang switcher -->
      <NavigationMenuLangSwitcher class="nav-menu__market" />
    </nav>
  </WidgetNavigationBar>
  <!-- Overlay -->
  <WidgetOverlayFullScreen class="nav-menu__overlay" ref="overlay">
    <div class="nav-menu__overlay__wrap" data-lenis-prevent>
      <!-- Primo Livello -->
      <NavigationMenuLinks
        class="nav-menu__links --main"
        ref="mainMenu"
        :links="linksMain"
        @open-sub-menu="toggleSubMenu"
        @open-search="toggleSearch(true)"
      />
      <!-- Secondo Livello Prodotti -->
      <NavigationMenuLinks
        class="nav-menu__links --products"
        ref="subMenuProducts"
        :links="linksSubmenuProducts"
        @open-sub-menu="toggleSubMenu"
      />

      <!-- Highlights -->
      <NavigationMenuHighlight
        v-if="highlight"
        class="nav-menu__highlight"
        ref="menuHighlight"
        :highlight="highlight"
      />

      <!-- Logo Right -->
      <NuxtLink
        :to="localePath({ name: 'index' })"
        class="nav-menu__overlay__logo hide-for-small"
      >
        <img
          src="~/assets/img/ico/logo-zava-white.svg"
          width="145"
          height="30"
          :alt="runtimeConfig.public.siteName"
        />
      </NuxtLink>

      <!-- Search -->
      <NavigationMenuSearch
        ref="subMenuSearch"
        @close-search="toggleSearch(false)"
      />
    </div>
  </WidgetOverlayFullScreen>
</template>

<script>
export default {
  name: 'NavigationMenu',
}
</script>

<script setup>
import { gsap } from 'gsap'

import { useCraftStore } from '@/store/craft.js'
import queries from '@/graphql/craft/queries/index.js'

import { navMenu } from '@/bucket/navigations.js'

const store = useCraftStore()
const route = useRoute()
const runtimeConfig = useRuntimeConfig()

const hamburger = ref(null)
const overlay = ref(null)
const mainMenu = ref(null)
const subMenuProducts = ref(null)
const menuHighlight = ref(null)
const subMenuSearch = ref(null)

const openOverlay = ref(false)
const showSubMenu = ref(false)
const showSearch = ref(false)

const tl = ref(null)

// TODO: Rispondere con nostra soluzione a https://github.com/nuxt-modules/apollo/discussions/529
// Fetch Data
const query = queries.navigation.getNavigation
const variables = computed(() => ({
  site: store.configuration.site,
  marketId: store.configuration.marketId,
}))
const { data } = await useGraphql(query, variables)

// Link statici e non
const linksMain = navMenu.linksMain
const linksSubmenuProducts = computed(() => {
  const links = []
  return links.concat(navMenu.linksBack).concat(
    data.value?.categoriesEntries
      ?.map((category) => {
        return {
          title: category.title,
          route: { name: 'category-slug', params: { slug: category.slug } },
        }
      })
      .concat(navMenu.linksSubMenuProducts)
  )
})

const highlight = computed(() =>
  data.value?.globalSet?.highlightContent?.find(
    (item) => item.highglightMarket.length > 0
  )
)

// Gestisco Overlay
const toggleOverlay = () => {
  if (tl.value?.isActive()) {
    tl.value.progress(1)
  }
  openOverlay.value = !openOverlay.value
}
watch(
  () => openOverlay.value,
  (openOverlay) => {
    tl.value = openOverlay
      ? animateOverlayIn().duration(1)
      : animateOverlayOut().duration(1.2)
    tl.value.play()
    showSubMenu.value = null
  }
)
watch(
  () => route.path,
  () => {
    if (openOverlay.value) {
      toggleOverlay()
    }
  }
)

// Gestisco Submenu
const toggleSubMenu = (action) => {
  showSubMenu.value = action
}

const toggleSearch = (value) => {
  showSearch.value = value
}

watch(
  () => showSubMenu.value,
  async (showSubMenu) => {
    const content = document.querySelector(
      '.nav-menu__overlay .overlay-full-screen__content'
    )
    if (showSubMenu === 'main') {
      await subMenuProducts.value.animateOut().duration(0.6).play()
      content.scrollTo(0, 0)
      await mainMenu.value.animateIn().duration(0.6).play()
    }
    if (showSubMenu === 'products') {
      await mainMenu.value.animateOut().duration(0.6).play()
      content.scrollTo(0, 0)
      await subMenuProducts.value.animateIn().duration(0.6).play()
    }
  }
)

watch(
  () => showSearch.value,
  async (showSubMenu) => {
    if (showSubMenu) {
      await subMenuSearch.value.animateIn().duration(0.4).play()
    } else {
      await subMenuSearch.value.animateOut().duration(0.4).play()
    }
  }
)

// Animazioni
const animateOverlayIn = () => {
  const tl = gsap.timeline({
    paused: true,

    onComplete: () => {
      tl.kill()
    },
  })

  tl.add(hamburger.value.animateIn().play())
  tl.add(overlay.value.animateIn().play(), '<')
  if (mainMenu.value) {
    tl.add(mainMenu.value.animateIn().play())
  }
  if (menuHighlight.value) {
    tl.add(menuHighlight.value.animateIn().play(), '<')
  }

  return tl
}

const animateOverlayOut = () => {
  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      tl.kill()
    },
  })

  tl.add(hamburger.value.animateOut().play())
  tl.add(overlay.value.animateOut().play(), '<')

  if (mainMenu.value && (!showSubMenu.value || showSubMenu.value === 'main')) {
    tl.add(mainMenu.value.animateOut().play())
  }
  if (showSubMenu.value === 'products') {
    tl.add(subMenuProducts.value.animateOut().play())
  }

  if (menuHighlight.value) tl.add(menuHighlight.value.animateOut().play(), '<')

  return tl
}
</script>

<style lang="scss">
@import './style.scss';
</style>
