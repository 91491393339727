<template>
  <main class="layout-default">
    <WidgetMouseCursor />
    <SeoTemplatePreset>
      <NavigationMenu />
      <slot />
      <FooterButtons />
      <FooterMain />
    </SeoTemplatePreset>
  </main>
</template>

<script setup></script>
