<template>
  <div class="nav-menu-search" ref="menuSearch" data-animate="menu-search">
    <div class="nav-menu__links__separator --small-link --bottom">
      <button class="link-menu-1 --ico-back" @click="closeSearch">
        <img
          src="~/assets/img/ico/ico-nav-arrow-back.svg"
          alt="Ico nav arrow back"
          width="16"
          height="19"
        />
        <span>{{ $t(`navigation.back`) }}</span>
      </button>
    </div>

    <!-- Form Area  -->
    <div class="nav-menu-search__wrap">
      <SearchForm :reset="forceReset" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavMenuSearch',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  show: Boolean,
})

const emit = defineEmits(['close-search'])

const menuSearch = ref(null)
const forceReset = ref(false)

const tl = ref(null)

const closeSearch = () => emit('close-search')

watch(
  () => props.show,
  (show) => {
    if (!tl.value?.isActive()) {
      tl.value = show ? animateIn() : animateOut()
      tl.value.duration(0.5).play()
    }
  }
)

// Animazioni
const animateIn = (params) => {
  const parent = menuSearch.value

  const tl = gsap.timeline({
    paused: true,
    onStart: () => {
      forceReset.value = false
    },
    onComplete: () => {
      tl.kill()
    },
    ...params,
  })

  tl.addLabel('start')

  tl.to(
    parent,
    {
      autoAlpha: 1,
    },
    'start'
  )

  return tl
}

const animateOut = (params) => {
  const parent = menuSearch.value

  const tl = gsap.timeline({
    paused: true,

    onComplete: () => {
      tl.kill()
      gsap.set([parent], {
        clearProps: 'all',
      })
      forceReset.value = true
    },
    ...params,
  })

  tl.addLabel('start')

  tl.to(
    parent,
    {
      autoAlpha: 0,
    },
    'start'
  )

  return tl
}

defineExpose({
  animateIn,
  animateOut,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
