<template>
  <div class="nav-menu-highlight" ref="menuHighlight" data-animate="highlights">
    <!-- Border  -->
    <div
      class="nav-menu-highlight__border"
      data-animate="highlight-border"
    ></div>
    <!-- Articolo -->
    <NuxtLink :to="link" class="nav-menu-highlight__content">
      <h2
        v-if="highlight.highlightTitle"
        class="text text-uppercase nav-menu-highlight__title"
      >
        <span data-animate="highlight-title">{{
          highlight.highlightTitle
        }}</span>
      </h2>
      <figure
        v-if="highlight.highlightImage?.[0]"
        class="nav-menu-highlight__image"
      >
        <!-- Image cover -->
        <div
          class="nav-menu-highlight__cover"
          data-animate="highlight-cover"
        ></div>
        <!-- Image  -->
        <img
          :src="highlight.highlightImage[0].url"
          :alt="highlight.highlightImage[0].title"
          :width="highlight.highlightImage[0].width"
          :height="highlight.highlightImage[0].height"
          loading="lazy"
        />
      </figure>
    </NuxtLink>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuHighlight',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { useCraftStore } from '@/store/craft.js'

const props = defineProps({
  show: Boolean,
  highlight: Object,
})

const { configuration } = useCraftStore()

const menuHighlight = ref(null)
const tl = ref(null)

const link = computed(() => {
  const market = configuration.markets.find(
    (market) => market.id === configuration.marketId
  )?.key
  return market === 'en-us'
    ? props.highlight?.highlightUrlUsa
    : props.highlight?.highlightUrl
})

watch(
  () => props.show,
  (show) => {
    if (!tl.value?.isActive()) {
      tl.value = show ? animateIn() : animateOut()
      tl.value.play()
    }
  }
)

// Animazioni
const animateIn = (params) => {
  const parent = menuHighlight.value
  const border = parent.querySelector('[data-animate="highlight-border"]')
  const title = parent.querySelector('[data-animate="highlight-title"]')
  const cover = parent.querySelector('[data-animate="highlight-cover"]')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      tl.kill()
    },
    ...params,
  })

  tl.set(parent, { autoAlpha: 1 })
  tl.to(
    border,
    {
      scaleY: 1,
    },
    'start'
  )
  tl.to(
    title,
    {
      y: '0%',
    },
    'start'
  )
  tl.to(
    cover,
    {
      scaleX: 0,
    },
    'start'
  )

  return tl
}

const animateOut = (params) => {
  const parent = menuHighlight.value
  const border = parent.querySelector('[data-animate="highlight-border"]')
  const title = parent.querySelector('[data-animate="highlight-title"]')
  const cover = parent.querySelector('[data-animate="highlight-cover"]')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      tl.kill()
      gsap.set([parent, border, title, cover], {
        clearProps: 'all',
      })
    },
    ...params,
  })

  tl.to(
    border,
    {
      scaleY: 0,
    },
    'start'
  )
  tl.to(
    title,
    {
      y: '101%',
    },
    'start'
  )
  tl.to(
    cover,
    {
      scaleX: 1,
    },
    'start'
  )
  tl.set(parent.value, {
    autoAlpha: 0,
  })

  return tl
}

defineExpose({
  animateIn,
  animateOut,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
