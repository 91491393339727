<template>
  <div class="search-form">
    <!-- Search -->
    <div class="search-form__input">
      <FormKit
        type="text"
        name="search"
        v-model="search"
        :placeholder="$t(`navigation.searchPlaceholder`)"
      />
      <button
        class="search-form__cancel"
        @click="cancel"
        v-if="searchText !== ''"
      >
        <img
          src="~/assets/img/ico/ico-delete-light.svg"
          alt="Ico delete light"
          width="14"
          height="14"
        />
      </button>
    </div>
    <!-- Results -->

    <div class="search-form__results m-top-6">
      <template v-if="entries.length">
        <SearchFormResult
          :key="entry.id"
          :result="entry"
          v-for="entry in entries"
        />
      </template>
      <template v-else-if="searchText !== ''">
        <p class="text search-form__results__no-result">
          {{ $t('bucket.searchFail') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchForm',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'

import { useCraftStore } from '@/store/craft.js'
import queries from '@/graphql/craft/queries/index.js'

const props = defineProps({
  reset: Boolean,
})

const { configuration } = useCraftStore()

const emit = defineEmits(['searched'])

const search = ref('')
const searchText = ref('')

const query = queries.search.getSearch
const variables = computed(() => ({
  site: configuration.site,
  marketId: configuration.marketId,
  search: searchText.value,
}))

const { data } = await useGraphql(query, variables, {
  executeSSR: false,
})

const entries = computed(() => {
  return searchText.value !== ''
    ? Object.values(data?.value ?? {}).reduce(
        (acc, current) => [...acc, ...current],
        []
      )
    : []
})

watch(
  () => search.value,
  debounce((search) => {
    if (search !== '') {
      searchText.value = transformString(search)
    } else {
      searchText.value = ''
    }
  }, 500)
)

watch(
  () => props.reset,
  (reset) => {
    if (reset) {
      cancel()
    }
  }
)

const cancel = () => {
  search.value = ''
}

const transformString = (inputString) => {
  const searchSplittedString = inputString
    .split(' ') // Split the string by spaces
    .map((word) => `*${word}*`) // Wrap each word with asterisks
    .filter((word) => word !== '**')
    .join(' OR ') // Join the words with ' OR '

  return `*${inputString}* OR ${searchSplittedString}`
}

defineExpose({
  cancel,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
